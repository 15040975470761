@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');



.otp-box {
    margin: 90px auto;
}

.otp-form {
    align-items: center;
}

.otp-box h1 {
    padding: 20px 10px;
}

.otp-form .otp-submit-btn {
    background: #C70101;
    width: 150px;
    border: none;
    margin: auto;
    display: flex;
    justify-content: center;
    padding: 11px 0px;
    margin-top: 20px;
    border-radius: 10px;
    color: #fff;
    height: 48px;
    margin-bottom: 30px;

}

.otp-form input {
    background-color: #efefef;
    border: 1px solid #dbdbdb;
    font-family: Raleway, sans-serif;
    font-size: 24px;
    font-weight: 200;
    height: 65.3px;
    line-height: 50px;
    margin: 0 13px;
    text-align: center;
    width: 60.9px;
}

a.btn:focus,
a.btn:active,
a.btn:visited {
    outline: none !important;
    box-shadow: none !important;
}

.schedulecard {
    border: 1px solid;
    border-radius: 15px;
    padding: 5px;
    box-shadow: 1px 1px 20px 5px #888888;
    margin-bottom: 20px;
}

button:focus,
input:focus,
select:focus,
textarea:focus,
a:focus {
    outline: none !important;
    box-shadow: none !important;
}

button:active,
input:active,
select:active,
textarea:active {
    outline: none !important;
    box-shadow: none !important;
}

.preloader-css {
    position: absolute;
    top: 40%;
    left: 42%;
    z-index: 9999;
}

.preloader-css img {
    width: 40%;
}

.row.pt-5 {
    min-height: 250px;
}
.btn-outline-danger {
    font-weight: 700;
}
.btn-outline-danger:hover {
   background: #fff;
}

button,
input,
select,
textarea {
    outline: none !important;
    box-shadow: none !important;
}

a:hover {
    text-decoration: none;
}

.top-header {
    background-color: #F0F0F0;
}

.logout-button {
    background: #C70101;
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 25.5px;
    padding: 2px 20px;
    margin: 5px;
    border: none;
}

.nav-item .nav-link .dropdown-menu a {
    padding-right: 40px !important;
    min-width: fit-content !important;
}

.navbar ul li:nth-child(6) .dropdown-menu {
    min-width: fit-content !important;
}


/* .navbar ul li:last-child {
    background: #C70101;
    border-radius: 10px !important;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 25.5px;
    padding: 15px !important;
    margin: 5px 5px 5px 20px;
    border: none;
}
.navbar ul li:last-child a {
    background: #C70101;
    color: #fff !important;
    line-height: 3.5px !important;
    padding: 8px 1px !important;
} */

ul.social_icon li {
    display: inline-block;
    padding: 0 0 0 15px;
}

ul.social_icon {
    margin: 0;
    padding: 6px 0 0 0;
    float: right;
}

body {
    font-family: 'Inter', sans-serif;
    padding-right: 0px !important;
}

ul.social_icon a {
    color: #000000;
    font-size: 12px;
    font-weight: 500;
    text-transform: capitalize;
    line-height: 14.52px;
}

.bottom_manu_margin {
    margin-top: 45px;
}

img.appstore {
    height: 30px;
}

.modal-open {
    overflow: visible !important;
}

.paybox {
    border: 1px solid rgb(194, 110, 110);
    padding: 20px;
    border-radius: 15px;
}

.pay-box {
    margin-left: 100px;
    justify-content: center;
    align-items: center;
}

.pay-box button {
    width: 100px;
    margin-left: 13px;
    font-family: 'FontAwesome';
    font-size: 16px;
}

.paybox p {
    color: #000000;
    font-size: 12px;
    font-weight: 500;
    text-transform: capitalize;
    line-height: 14.52px;
}

.top_left p {
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    color: #000;
    padding: 12px 0;
    margin: 0;
}

.top_left p a {
    color: #fff;
    font-family: "Inter", Sans-serif;
    font-size: 10px;
    font-weight: 400;
    line-height: 12.3px;
    background-color: #FFA500;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #FFA500;
    border-radius: 50px 50px 50px 50px;
    padding: 7px 30px 7px 30px;
}

.top_left p a:hover {
    color: #FFA500;
    background-color: #FFFFFF;
    border-color: #FFA500;
}

div#collapsibleNavbar ul li a {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    color: #000;
    padding: 20px 0 0 40px;
}

.dropdown-menu.show {
    -webkit-box-shadow: 0 4px 10px -2px rgb(0 0 0 / 10%);
    box-shadow: 0 4px 10px -2px rgb(0 0 0 / 10%);
    padding: 0;
    width: 170px;
    background-color: #fff;
}

.dropdown-menu.show a {
    padding: 14px 25px !important;
    border-bottom: 1px solid #6666668f;
    text-align: center;
}





footer {
    background-color: #F0F0F0;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 40px 0px 40px 0px;
}

.bottom_footer {
    background-color: #E3E3E3;
    padding: 10px 0;
}

.bottom_footer p {
    font-size: 13px;
    font-weight: 400;
    line-height: 17.32px;
    margin-bottom: 0;
    padding: 13px 0 0 0;
    color: #000;
}

.bottom_footer p {
    font-size: 13px;
    font-weight: 400;
    line-height: 17.32px;
    margin-bottom: 0;
    padding: 10px 0 0 0;
    color: #000;
}

.footer_text h6 img {
    margin: 0px 6px 0 0;
}

footer h3 {
    font-size: 15px;
    font-weight: 600;
    line-height: 18.15px;
    color: #000;
    margin: 0;
}

footer p {
    font-size: 14px;
    font-weight: 400;
    line-height: 18.65px;
    color: #000;
    padding-top: 25px;
}

ul.foot_play {
    margin: 30px 0 10px;
}

footer h3:after {
    content: "";
    border-bottom: 3px solid #C70101;
    display: block;
    width: 30%;
    margin: 7px 0 15px;
}

footer ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

footer ul.foot_play li {
    display: inline-block;
}

footer h4 {
    font-size: 1.2em;
    color: #000;
    font-weight: bold;
    padding-top: 18px;
    margin-bottom: 5px;
}

footer h5 {
    font-size: 14px;
    font-weight: 400;
    line-height: 18.65px;
}

footer h6 {
    font-size: 15px;
    font-weight: 600;
    line-height: 18.15px;
    color: #000;
    padding-top: 5px;
}

.footer_inner ul li a {
    font-size: 13px;
    font-weight: 400;
    line-height: 32.5px;
    color: #000;
}

section.bradecum {
    background: url('img/4.png');
    width: 100%;
    height: 130px;
    padding: 30px 0 0;
    background-size: cover;
}

section.bradecum h2 {
    font-size: 36px;
    font-weight: 600;
    line-height: 43.57px;
    color: #000;
    margin: 0;
}

section.bradecum p {
    font-size: 20px;
    font-weight: 500;
    line-height: 24.2px;
    color: #000;
}

.course_list h3 {
    font-size: 18px;
    font-weight: bold;
    line-height: 30px;
    padding-top: 10px;
    margin-bottom: 10px;
    color: #000;
}

.course_inner_list h3 {
    font-size: 15px;
    font-weight: bold;
    line-height: 30px;
    padding-top: 10px;
    margin-bottom: 10px;
    color: #000;
}

.course_list img,
.course_inner_list img {
    width: 100%;
    height: auto;
    border-radius: 15px;
}

.unit_list h3 {
    font-size: 14px !important;
}

.show_video {
    background: #EAEAEA;
    border-radius: 4px;
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 25.5px;
    padding: 7px 20px;
    margin: 5px;
}

.play {
    position: absolute;
    right: 0;
    left: 0;
    margin: 0 auto;
    width: 40px !important;
    top: 33%;
}

.breadcrumb:before {
    content: " ";
    display: table;
}

.breadcrumb>li+li:before {
    content: "/\A0";
    padding: 0 5px;
    color: #ccc;
}

.breadcrumb>li+li:before {
    content: "/\00a0";
    padding: 0 5px;
    color: #ccc;
}

.breadcrumb {
    padding: 8px 15px;
    margin-bottom: 21px;
    list-style: none;
    background-color: #ecf0f1;
    border-radius: 4px;
}

.player {
    background-color: #2c3e50 !important;
    border-color: transparent !important;
    padding: 30px 30px 6px 30px;
    color: #fff;
}

.video_list h5 {
    font-size: 12px;
    font-weight: bold;
    padding-top: 10px;
    /* padding-bottom: 10px; */
}

.video_list h6 {
    font-size: 14px;
    line-height: 0.5;
}

.video_list img {
    border-radius: 15px;
}

.video_list p {
    font-size: 13px;
}

.video_list {
    margin-bottom: 30px;
}

.back_video img {
    width: 40px;
    position: absolute;
    left: 29px;
    top: 13px;
    z-index: 9999;
}

.video_inner,
.video_inner:hover {
    color: #000;
}

.subscribe,
.subscribe:hover {
    background: #C70101;
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 25.5px;
    padding: 7px 20px;
    margin: 5px;
}

.details,
.details:hover {
    background: #F8A401;
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 25.5px;
    padding: 7px 20px;
    margin: 5px;
}

.course_bar ul li a {
    border: 1px solid #E6E6E6;
    border-radius: 44px;
    background-color: #fff;
    color: #000;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    text-transform: capitalize;
    padding: 10px 20px;
}

.course_bar ul {
    list-style: none;
    margin: 0;
    padding: 30px 0 0 0;
    text-align: center;
}

.course_list,
.course_inner_list {
    margin-bottom: 50px;
}

.course_bar ul li {
    padding: 0 0 0 10px;
    display: inline-block;
}

.course_bar ul li a.active {
    background: #C70101;
    color: #fff;
    border-color: #C70101;
}

/*Login*/
.login {
    background: #fafafa;
    width: 100%;
    height: auto;
    padding: 40px 0;
    display: flex;
    align-items: center;
}

.register {
    background: #fafafa;
    width: 100%;
    height: auto;
    padding: 40px 0;
    display: flex;
    align-items: center;
}

.form_innner h2 {
    color: #C70101;
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 20px;
}

.form_innner form {
    box-shadow: 0px 4px 50px rgb(0 0 0 / 8%);
    background-color: #fff;
    padding: 50px;
    border-radius: 35px;
}

.form_innner img {
    margin: 35px auto;
    display: block;
}

.course_list img {
    width: 100%;
}

.form_innner {
    padding-top: 15%;
}

.form-check input {
    height: auto !important;
    width: auto !important;
}

.form_innner input,
.form_innner select {
    color: #000;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 7px;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #000;
    opacity: 0.5;
    width: 100%;
    height: 40px;
}

.form_innner input::placeholder,
.form_innner select option {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #000;
    opacity: 0.5;
}

.form_innner label {
    font-weight: 400;
    font-size: 13px;
    color: #000;
}

.forgot,
.forgot:hover {
    font-weight: 400;
    font-size: 13px;
    line-height: 30px;
    float: right;
    color: #000;
}

.submit,
.submit:hover,
.submit:active {
    background: #C70101 !important;
    border-radius: 7px;
    border: 0;
    width: 100%;
    margin: 22px 0 0;
    font-weight: 700;
    font-size: 15px;
    padding: 12px 0;
    line-height: 18px;
}

.new_user,
.new_user:hover {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    margin: 38px auto;
    display: block;
    color: #000;
}

.new_user span {
    color: #C70101;
    padding: 0 0 0 8px;
}

.back_home,
.back_home:hover {
    background: #FFECEC;
    border: 1px solid #C70101;
    border-radius: 7px;
    color: #C70101;
    padding: 8px 15px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    position: relative;
    top: -60px;
    right: 80px;
}

.regiter_img {
    padding-top: 14%;
}

.regiter_img a.back_home {
    top: -90px;
}

.form-check-input {
    margin-top: 7px;
}


div#subscribe_modal h3 {
    color: #C70101;
    width: 100%;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 0;
}

div#subscribe_modal h5 {
    padding-top: 20px;
    font-size: 18px;
    font-weight: 600;
}

div#subscribe_modal table.table {
    border: 2px solid #dee2e6;
    border-collapse: collapse;
}

div#subscribe_modal table.table tr,
div#subscribe_modal table.table th {
    border: 0;
}

.faculty-details {
    font-weight: bolder;
    line-height: 2;
    text-align: left;
}

.profile {
    width: 100%;
    border: 12px solid #C70101;
    padding: 10px;
    margin: 20px 0;
    font-family: 'Inter', sans-serif;
}

.dropdown-menu.show.navbarrelative {
    margin-top: -55%;
    margin-left: 103%;
}

.profile-des p {
    font-family: 'Inter', sans-serif;
    padding: 4px;
    font-size: 16px;
    font-weight: 600;
    line-height: 15px;
    color: #000000;
}

.profile-card {
    justify-content: center;
    text-align: center;
    height: auto;
}

.profile-card h6 {
    font-family: 'Inter', sans-serif;
    color: #C70101;
}

.profile-card img {
    height: 120px;
    width: 120px;
    border-radius: 100px;
    background-color: #ffffff;
}

.activeVideo {
    color: blue;
}

.box_shadow {
    border: 1px solid;
    padding: 5px;
    border-radius: 3%;
    box-shadow: 1px 1px #888888;
}

.red_color {
    color: #C70101;
}

/*--------------- manish-css- 15/09/23 ---------------*/
.h-80vh{
    height: 80vh;
}
.mt-90{
    margin-top: 90px;
}
.right-icon img{
    width: 60px;
}
.thank-content h2{
    color: #282A2B;
    font-family: "Poppins", Sans-serif;
    font-size: 50px;
    font-weight: 600;
}
.thank-content p{
    color: #4F5254;
    font-family: "Poppins", Sans-serif;
    font-size: 18px;
    font-weight: 600;
}
.website-btn a{
    background-color: #DA251D;
    color: #fff;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-radius: 8px 8px 8px 8px;
    padding: 8px 20px;
    border: none;
    font-size: 15px;
    font-weight: 500;
}



@media only screen and (max-width: 767px) {

    .video_list img,
    .course_list img {
        width: 75%;
    }

    .preloader-css {
        top: 55%;
        left: 40%;
    }

    div#subscribe_modal table.table tr th {
        font-size: 15px;
    }

    div#subscribe_modal table.table tr td {
        font-size: 14px;
    }

    .register,
    .login {
        height: auto;
    }

    .login {
        padding-top: 40px;
    }

    .back_home {
        top: -30px !important;
        right: 0;
    }

    ul.social_icon {
        float: unset;
        margin: 4px auto 10px;
        display: block;
        text-align: center;
        line-height: 2.5;
    }

    .menu-area {
        padding: 10px 0;
    }

    .navbar {
        float: right;
        top: 10px;
    }

    div#collapsibleNavbar {
        position: absolute;
        width: 320px;
        right: 18%;
        background-color: #fff;
        top: 100%;
        z-index: 999999;
        padding: 20px;
    }

    .div_hide {
        text-align: center;
    }

    .bottom_manu_margin {
        margin-top: unset;
    }




    .otp-box {
        margin: 100px auto;
    }

    .otp-form input {
        background-color: #efefef;

        font-family: Raleway, sans-serif;
        font-size: 24px;
        font-weight: 200;
        height: 65.3px;
        line-height: 40px;
        margin: 0 13px;
        text-align: center;
        width: 60.9px;
    }
}

/* ---------- 05/09/23 - CSS ---------- */

.schedulecard {
    margin-right: 40px;
    padding: 10px 10px 0px 10px;
}

.text-red{
    color: #C70101 !important;
}
.bg-pink{
    background: #F9DDDD;
}
.schedulecard{
    border: none;
}
.schedulecard .video_list{
    margin-bottom: 15px;
}
.faculty-details h4{
    color: #C70101;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}
.faculty-details h6{
    color: #545050;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 5px;
}
.faculty-details th{
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    width: 45%;
    vertical-align: top;
}
.faculty-details td{
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    width: 55%;
}
.faculty-details .date-time-detail{
    background: #F9DDDD;
    margin: 0px -10px -16px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 0px 10px;
    display: flex;
}
.faculty-details .date-time-detail h5{
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    width: 45%;
    vertical-align: top;
}
.faculty-details .date-time-detail p{
    width: 55%;
    padding-top: 6px;
}
.video_list img, .course_list img{
    margin-bottom: 10px;
}
.faculty-details hr {
    margin-bottom: 6px;
    margin-top: 12px;
}

@media only screen and (max-width: 600px) {
.course_bar ul li a {
    border: 1px solid #E6E6E6;
    border-radius: 44px;
    background-color: #fff;
    color: #000;
    font-weight: 400;
    font-size: 16px;
    line-height: 55px;
    text-transform: capitalize;
    padding: 10px 20px;
}
.schedulecard {
    margin: 30px;
    padding: 10px;
}
.video_list img, .course_list img{
    width: 100%;
    
}
.schedulecard .video_list {
    margin-bottom: 0px;
}
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {

    .schedulecard {
        margin: 30px 20px;
        padding: 20px;
    }
    .faculty-details .date-time-detail {
        background: #F9DDDD;
        margin: 0px -20px -16px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        padding: 0px 20px;
        margin-bottom: -20px;
    }
    .schedulecard .video_list{
        margin-bottom: 0px;
    }
}